var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Eligibility")]),
      _vm._l(_vm.eligibilityTypes, function(rootTaxonomy) {
        return _c(
          "gov-grid-row",
          { key: rootTaxonomy.id },
          [
            _c(
              "gov-grid-column",
              { attrs: { width: "two-thirds" } },
              [
                _c("gov-heading", { attrs: { size: "m" } }, [
                  _vm._v(_vm._s(rootTaxonomy.name))
                ]),
                _vm.serviceHasEligibilityCriteria(rootTaxonomy)
                  ? _c(
                      "div",
                      [
                        _c("ck-taxonomy-list", {
                          attrs: {
                            taxonomies: rootTaxonomy.children,
                            filteredTaxonomyIds:
                              _vm.service.eligibility_types.taxonomies,
                            bullet: true
                          }
                        }),
                        _vm.serviceEligibilityCustomValue(rootTaxonomy.name)
                          ? _c("gov-body", [
                              _vm._v(
                                "Custom Value:\n          " +
                                  _vm._s(
                                    _vm.serviceEligibilityCustomValue(
                                      rootTaxonomy.name
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("gov-body", [
                      _vm._v(
                        "No specific " +
                          _vm._s(rootTaxonomy.name) +
                          " criteria specified"
                      )
                    ])
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }